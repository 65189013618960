/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

@import '~ngx-toastr/toastr';

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}
.status-list li a{cursor: pointer; }
.status-list li a:hover{color: #485ec4!important; }
.status-list .modal-header a, .modal-header a{cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #ddd;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  color: #000;
  transition: 0.3s all ease;
}
.status-list .modal-header a:hover{background: #485ec4; color: #fff!important;}


.scrollbar_add{overflow: scroll; height: 600px; width: 1250px;}
@media (min-width: 1200px) {
  .chat-leftsidebar {
    min-width: 300px!important;
  }
}
